import React from "react";

import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import {
  Stack,
  Typography,
  Container,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Link,
  Box,
  TextField,
  Divider,
  Grid,
  InputAdornment,
  TextareaAutosize,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { useFormikContext } from "formik";
// import { ProjectContentSections } from "../ProjectContentSections";
// import { InitinalValueProjectContent } from "../InitinalValuesProjectContent";
// import {
//   IInitinalValueProjectContent,
//   InitinalValueProjectContent,
// } from "../InitinalValuesProjectContent";
// import ProjectIcons from "../project-icons/ProjectIcons";
// import UpCommingMedia from "./UpCommingMedia";
import { state } from "../../../../utils/States";
import { useSelector } from "react-redux";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { RouteConstants } from "routes/RouteConstants";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { PromiseCreationContainer } from "pages/promise-management/promise-creation/PromiseCreation.style";
import CurrentInfraStory from "pages/project-management/project-creation/upcomming-projects/upcommingProjectSections/CurrentInfraStory";
import UpcomingInfraStory from "pages/project-management/project-creation/upcomming-projects/upcommingProjectSections/UpcomingInfraStory";
import TourismAround from "pages/project-management/project-creation/upcomming-projects/upcommingProjectSections/TourismAround";
import KeyPillars from "pages/project-management/project-creation/upcomming-projects/upcommingProjectSections/KeyPillars";
import { InitinalValueProjectContent } from "pages/project-management/project-creation/upcomming-projects/UpCommingInitialValues";
const SingleUpcommingProject = () => {
  //   const {
  //     values,
  //     handleChange,
  //     handleSubmit,
  //     setFieldValue,
  //     resetForm,
  //     handleBlur,
  //     errors,
  //     touched,
  //     isValid,
  //   } = useFormikContext<IInitinalValueProjectContent>();
  const singleProject = useSelector((state: any) => {
    return state?.upCommingProjects?.singleProduct;
  });
  console.log("singleProject", singleProject);

  const location: any = useLocation();
  const navigate = useNavigate();
  console.log("location", location.state);

  const breadcrumbRoutes: any[] = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Projects",
      route: RouteConstants.projectList,
    },
    {
      name: `View - ${location?.state?.project?.launchName}`,
      route: RouteConstants.upCommingProjectUpdate,
    },
  ];

  return (
    <>
      <Formik
        initialValues={{
          ...singleProject,
          // opportunityDoc: singleProject?.opportunityDoc
          //   ? singleProject?.opportunityDoc
          //   : InitinalValueProjectContent?.opportunityDoc,
          // keyPillars: singleProject?.keyPillars
          //   ? singleProject?.keyPillars
          //   : InitinalValueProjectContent?.keyPillars,
          // isKeyPillarsActive: singleProject?.isKeyPillarsActive
          //   ? singleProject?.isKeyPillarsActive
          //   : InitinalValueProjectContent?.isKeyPillarsActive,
          // isKeyPillarsActiveForWeb: singleProject?.isKeyPillarsActiveForWeb
          //   ? singleProject?.isKeyPillarsActiveForWeb
          //   : InitinalValueProjectContent?.isKeyPillarsActiveForWeb,
        }}
        onSubmit={(values: any) => {}}
        enableReinitialize
      >
        <>
          <div>
            <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
          </div>
          <Container className="main-container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginInline: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  edge={"start"}
                  onClick={() => navigate(RouteConstants.projectList)}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h5">{`${location?.state?.project?.launchName} - #ID${location?.state?.project?.id}`}</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ButtonComponent
                  onClick={() => navigate(RouteConstants.projectList)}
                >
                  Cancel
                </ButtonComponent>
                <LoadingButtonComponent
                  onClick={() =>
                    navigate(RouteConstants.upCommingProjectUpdate, {
                      state: { project: location?.state?.project },
                    })
                  }
                  // type={ "submit" }
                  style={{ marginLeft: "1rem" }}
                >
                  Edit
                </LoadingButtonComponent>
              </div>
            </div>
            <PromiseCreationContainer sx={{ padding: 3 }}>
              <div>
                <TextFieldComponent
                  label={"Launch Name"}
                  name="launchName"
                  disabled={true}
                  value={singleProject?.launchName}
                  // inputProps={{
                  //   "data-testId": "project-id-textfield",
                  // }}
                  //   onChange={handleChange}
                  style={{ marginTop: "4px" }}
                />
                {/* <TextFieldComponent
        
        label={"Location"}
        name="location"
        
        onChange={handleChange}
        style={{ marginTop: "4px" }}
      /> */}
                <TextFieldComponent
                  fullWidth
                  required
                  // disabled={isViewMode}
                  // helpericon={MediaAssets.ic_info}
                  disabled={true}
                  label={"Short Description"}
                  value={singleProject?.shortDescription}
                  name="shortDescription"
                  placeholder="short description"
                  //   onChange={handleChange}
                />
                <TextFieldComponent
                  fullWidth
                  label={"ProjectPhoneNumber"}
                  disabled={true}
                  value={singleProject?.projectPhoneNumber}
                  name="projectPhoneNumber"
                  // inputProps={{
                  //   "data-testId": "project-id-textfield",
                  // }}
                  //   onChange={handleChange}
                  style={{ marginTop: "4px" }}
                />
                <div
                // className={styles["accordion-global-padding"]}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="flexStart"
                    spacing={2}
                    mb={1}
                  >
                    <Typography variant="h6" color="#000">
                      Address
                    </Typography>
                  </Stack>
                </div>
                <div
                // className={styles["accordion-global-padding"]}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <TextFieldComponent
                        name="address.country"
                        variant="outlined"
                        label={"Country"}
                        disabled={true}
                        value={singleProject?.address?.country}
                        // onChange={handleChange}
                        // value={values?.address?.country}
                        fullWidth
                        // onBlur={handleBlur}
                        inputProps={{
                          "data-testId": "country",
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextFieldComponent
                        select
                        name="address.state"
                        variant="outlined"
                        label={"State"}
                        disabled={true}
                        value={singleProject?.address?.state}
                        // value={values?.address?.state}
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        fullWidth
                        // disabled={isViewMode}
                      >
                        {state.map((stateValue: string, key: number) => (
                          <MenuItem key={key} value={`${stateValue}`}>
                            {stateValue}
                          </MenuItem>
                        ))}
                      </TextFieldComponent>
                    </Grid>

                    <Grid item xs={3}>
                      <TextFieldComponent
                        fullWidth
                        name="address.city"
                        // onChange={handleChange}
                        label={"City"}
                        disabled={true}
                        value={singleProject?.address?.city}
                        // value={values?.address?.city}
                        // onBlur={handleBlur}
                        // onChange={handleTextInput}
                        inputProps={{
                          "data-testId": "city",
                        }}
                      />
                    </Grid>

                    {/* <Grid item xs={3}>
            <TextFieldComponent
              name="address.pinCode"
              variant="outlined"
              label={"PIN/ZIP Code"}
              fullWidth
              onChange={(event) => {
                if (shouldNumberOnly(event.target.value)) {
                  setFieldValue(`${event.target.name}`, event.target.value);
                }
              }}
              onBlur={handleBlur}
              value={values?.address?.pinCode}
              disabled={isViewMode}
              inputProps={{ maxLength: 6 }}
            />
          </Grid> */}
                  </Grid>
                </div>

                {/* </Grid> */}

                {/* <Grid item xs={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  name="launchName"
                  helpericon={MediaAssets.ic_info}
                  helpericoncontent={
                    "This will be the display name for this project on Customer App"
                  }
                  label={"Display Name"}
                  value={values?.launchName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: GeneralInfoConst.launchNameCharacterLimit,
                    "data-testId": "launch-name",
                  }}
                  disabled={isViewMode}
                />
              </Grid>

              <Grid mt={2.5} item xs={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  label={"CRM Launch Name"}
                  value={values?.generalInfoEscalationGraph?.crmLaunchPhaseName}
                  disabled
                />
              </Grid>

              <Grid mt={2.5} item xs={3} md={2.5} lg={2.5} xl={3}>
                <TextFieldComponent
                  fullWidth
                  label={"CRM Project Name"}
                  value={values?.generalInfoEscalationGraph?.crmProjectName}
                  disabled
                />
              </Grid>

              {location?.state?.isEditMode && !location?.isProjectClone && (
                <Grid
                  sx={{ textAlign: "end" }}
                  mt={3}
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <LoadingButtonComponent
                    loading={isFetchCrmUpdatesLoading}
                    disabled={disableFetchUpdate}
                    onClick={handleFetchUpdate}
                  >
                    Fetch Updates
                  </LoadingButtonComponent>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  required
                  disabled={isViewMode}
                  label={"Area Starting from"}
                  name="areaStartingFrom"
                  value={values?.areaStartingFrom}
                  onChange={(event) => {
                    if (shouldNumberOnly(event.target.value)) {
                      setFieldValue(`${event.target.name}`, event.target.value);
                    }
                  }}
                  inputProps={{
                    "data-testId": "min-area-range",
                  }}
                  onBlur={handleBlur}
                  fieldhelpertext={
                    getIn(touched, "areaStartingFrom") &&
                    getIn(errors, "areaStartingFrom")
                  }
                  error={Boolean(
                    getIn(errors, "areaStartingFrom") &&
                      getIn(touched, "areaStartingFrom")
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">sq ft</InputAdornment>
                    ),
                  }}
                /> */}
                {/* </Grid> */}
                {/* <UpCommingMedia />
        <ProjectIcons /> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    columnGap: "2rem",
                  }}
                >
                  {singleProject?.bannerImage?.value?.url && (
                    <>
                      {" "}
                      <div style={{ width: "50%", marginTop: "20px" }}>
                        <Typography variant="h6" color="#000">
                          Banner Image
                        </Typography>
                        <img src={singleProject?.bannerImage?.value?.url} />
                      </div>
                    </>
                  )}

                  {singleProject?.projectIcon?.value?.url && (
                    <>
                      <div style={{ width: "50%", marginTop: "20px" }}>
                        <Typography variant="h6" color="#000">
                          Project Icon
                        </Typography>
                        <img src={singleProject?.projectIcon?.value?.url} />
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* <CurrentInfraStory isViewMode={true} />
              <UpcomingInfraStory isViewMode={true} />
              <TourismAround isViewMode={true} />
              <KeyPillars isViewMode={true} /> */}
            </PromiseCreationContainer>
          </Container>
        </>
      </Formik>
    </>
  );
};

export default SingleUpcommingProject;
