export const RouteConstants = {
  login: "/login",
  resetPassword: "/reset-password/:token",
  portalDashboard: "/portal-dashboard",
  userManagementDashboard: "/user-management-dashboard",
  projectManagementDashboard: "/project-management-dashboard",
  promiseManagementDashboard: "/promise-management-dashboard",
  pageManagementDashboard: "/page-management-dashboard",
  resourceManagementDashboard: "/resource-management-dashboard",
  marketingManagementDashboard: "/marketing-management-dashboard",
  customerManagementDashboard: "/customer-management-dashboard",
  notificationManagementDashboard: "/notification-management-dashboard",
  chatSupportManagementDashboard: "/chat-support-management-dashboard",
  blogManagementDashboard: "/blog-management-dashboard",
  landingPageManagementDashboard: "/landing-page-management-dashboard",

  blogCreation: "/blog-creation",
  landingPageUrlCreation: "/landing-page-url-creation",
  urlList: "/landing-page-url-list",
  userLog: "/user-log",
  userList: "/users",
  userCreation: "/user-creation",
  updateUser: "/user-creation/:id",
  auditLog: "/audit-log",

  myProfile: "/my-Profile",

  projectList: "/projects",
  projectCreation: "/project-creation",
  projectFaqCreation: "project-create-faq",
  drafts: "/drafts",
  mediaUpload: "/media-upload",
  mediaUploadList: "/medias",
  mediaBulkUpload: "/bulk-upload",
  projectCollections: "/project-collections",
  projectCollectionCreation: "/project-collection-creation",

  promiseList: "/promises",
  promiseCreation: "/promise-creation",

  insightList: "/insights",
  insightCreation: "/add-insight",

  paymentFormManagement: "/payment-form-management",
  formLists: "/payment-form-management/projects",
  projects: "/payment-form-management/projects/:id",
  investments: "/investments",

  faqList: "/faqs",
  faqCreation: "/faq-creation",

  promotionsAndOffersList: "/promotions-offers",
  promotionAndOffersCreation: "/promotions-offers-creation",

  nudgeCardList: "/nudge-cards",
  nudgeCardCreation: "/nudge-card-creation",

  referralCardList: "/referral-cards",
  referralCardCreation: "/referral-card-creation",

  updateList: "/updates",
  updateCreation: "/update-creation",

  testimonialsList: "/testimonials",
  testimonialCreation: "/testimonial-creation",

  mediaAndPrList: "/mediaAndPrs",
  mediaAndPrCreation: "/mediaAndPr-creation",

  customerList: "/customers",
  customerView: "/customer-view",
  customerFeedback: "/customer-feedback",
  blockedCustomersList: "blocked-customers",
  customerInvestmentDetails: "/customer-investment-detail",

  pageManagement: "/pageManagement",
  miscellaneous: "/miscellaneous",

  page1Hoabl: "/page1-hoabl",
  page2Investments: "/page2-investments",
  page3Promises: "/page3-promises",
  page4Portfolio: "/page4-portfolio",
  page5Profile: "/page5-profile",
  page6FooterTabs: "/page6-footer-tabs",

  hoablNewUser: "/hoabl-new-user",
  hoablInvestedUser: "/hoabl-invested-user",
  projectsNewUser: "/project-new-user",
  projectsInvestedUser: "/project-invested-user",
  schedulingVC: "/schedule-vc",

  notificationList: "/notifications",
  blogs: "/blogs",
  notificationCreation: "/notification-creation",

  appUpdates: "/app-update-notifications",
  appUpdateCreation: "/app-update-creation",

  pageNotFound404: "*",
  upCommingProject: "/ucomming-project",
  singleUpcommingProject: "/single-upcomming-project",
  upCommingProjectUpdate: "/update-upcomming-project",
  leadsList: "/leads-management",
};
