import { Constants } from "constants/Constants";
import { createActions, createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  isLoading: false,
  customerData: [],
  customerOverview:null,//customer Documents path + payment history stored in customerOverview.
  customerSummary:null,
  customerDocument:null,
  message: "",
  isSuccess: false,
  shouldOpenFlashDialog: false,
  totalCount:  Constants.defaultTotalCount,
  page:0,
  rowsPerPage:10,
  tabValue:"1",
  customerInvestmentDetails : {}
};

const { Types, Creators } = createActions({
  //customerData
  getCustomersRequest: ["data"],
  getCustomersSuccess: ["response"],
  getCustomersFailure: ["error"],
  //WatchList
  getCustomerWatchListRequest: ["data"],
  getCustomerWatchListSuccess: ["response"],
  getCustomerWatchListFailure: ["error"],
  //InvestmentSummary
  getCustomerInvestmentSummaryRequest: ["data"],
  getCustomerInvestmentSummarySuccess: ["response"],
  getCustomerInvestmentSummaryFailure: ["error"],
//Overview
  getCustomerOverviewRequest: ["data"],
  getCustomerOverviewSuccess: ["response"],
  getCustomerOverviewFailure: ["error"],
  //Document
  getCustomerDocumentRequest: ["data"],
  getCustomerDocumentSuccess: ["response"],
  getCustomerDocumentFailure: ["error"],

  // Customer Investment Details
  getCustomerInvestmentDetailsRequest: ["data"],
  getCustomerInvestmentDetailsSuccess: ["response"],
  getCustomerInvestmentDetailsFailure: ["error"],

  setCustomersState: ["key", "value"],
});

export const CustomersTypes = Types;
export const CustomerActions =Creators;

export const getCustomersRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCustomersSuccess = (state: any, action: any) => {
    
  return {
    ...state,
    isLoading: false,
    customerData: action?.response?.data?.data,
    totalCount: action?.response?.data?.totalCount,
  };
};

export const getCustomersFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,

    customerData: [],
    totalCount: 0,
  };
};
export const getCustomerWatchListRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCustomerWatchListSuccess = (state: any, action: any) => {
    
  return {
    ...state,
    isLoading: false,
    customerData: action?.response?.data?.data,
    totalCount: action?.response?.data?.totalCount,
  };
};

export const getCustomerWatchListFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,

    customerData: [],
    totalCount: 0,
  };
};
export const getCustomerInvestmentSummaryRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCustomerInvestmentSummarySuccess = (state: any, action: any) => {
    
  return {
    ...state,
    isLoading: false,
    customerSummary: action?.response?.data?.data,
  };
};

export const getCustomerInvestmentSummaryFailure = (state: any, action: any) => {

  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,


    customerOverview: null,
    customerSummary: null,

    totalCount: 0,
  };
};
export const getCustomerOverviewRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCustomerOverviewSuccess = (state: any, action: any) => {
    
  return {
    ...state,
    isLoading: false,
    customerOverview: action?.response?.data?.data,
  };
};

export const getCustomerOverviewFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,

    customerOverview: null,
    totalCount: 0,
  };
};
export const getCustomerDocumentRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
    customerDocument: null
  };
};

export const getCustomerDocumentSuccess = (state: any, action: any) => {
    
  return {
    ...state,
    isLoading: false,
    customerDocument: action?.response?.data?.data,
  };
};

export const getCustomerDocumentFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,

    customerDocument: null,
  };
};


// Customer Invested Details
export const getCustomerInvestmentDetailsRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCustomerInvestmentDetailsSuccess = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    customerInvestmentDetails: action?.response?.data?.data,
  };
};

export const getCustomerInvestmentDetailsFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.data?.message,
    customerInvestmentDetails: {}
  };
};

//customer set state
export const setCustomersState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CUSTOMERS_REQUEST]: getCustomersRequest,
  [Types.GET_CUSTOMERS_SUCCESS]: getCustomersSuccess,
  [Types.GET_CUSTOMERS_FAILURE]: getCustomersFailure,

  [Types.GET_CUSTOMER_WATCH_LIST_REQUEST]: getCustomerWatchListRequest,
  [Types.GET_CUSTOMER_WATCH_LIST_SUCCESS]: getCustomerWatchListSuccess,
  [Types.GET_CUSTOMER_WATCH_LIST_FAILURE]: getCustomerWatchListFailure,

  [Types.GET_CUSTOMER_INVESTMENT_SUMMARY_REQUEST]: getCustomerInvestmentSummaryRequest,
  [Types.GET_CUSTOMER_INVESTMENT_SUMMARY_SUCCESS]: getCustomerInvestmentSummarySuccess,
  [Types.GET_CUSTOMER_INVESTMENT_SUMMARY_FAILURE]: getCustomerInvestmentSummaryFailure,

  [Types.GET_CUSTOMER_OVERVIEW_REQUEST]: getCustomerOverviewRequest,
  [Types.GET_CUSTOMER_OVERVIEW_SUCCESS]: getCustomerOverviewSuccess,
  [Types.GET_CUSTOMER_OVERVIEW_FAILURE]: getCustomerOverviewFailure,

  [Types.GET_CUSTOMER_DOCUMENT_REQUEST]: getCustomerDocumentRequest,
  [Types.GET_CUSTOMER_DOCUMENT_SUCCESS]: getCustomerDocumentSuccess,
  [Types.GET_CUSTOMER_DOCUMENT_FAILURE]: getCustomerDocumentFailure,

  [Types.GET_CUSTOMER_INVESTMENT_DETAILS_REQUEST]: getCustomerInvestmentDetailsRequest,
  [Types.GET_CUSTOMER_INVESTMENT_DETAILS_SUCCESS]: getCustomerInvestmentDetailsSuccess,
  [Types.GET_CUSTOMER_INVESTMENT_DETAILS_FAILURE]: getCustomerInvestmentDetailsFailure,

  [Types.SET_CUSTOMERS_STATE]: setCustomersState,
});
//WATCHLIST
//INVESTMENT_SUMMARY
//OVERVIEW

//DOCUMENT