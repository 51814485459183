import { PopperPlacementType } from "@mui/material";
import { RouteConstants } from "routes/RouteConstants";

export type PropperStateType = {
  anchorEl: null;
  isSettingOpen: boolean;
  placement: PopperPlacementType;
};
export interface IObjectProps {
  [key: string]: any;
}
export interface IOptionProps {
  id?: number;
  title: string;
}

export const newNotificationPayload: IObjectProps = {
  topicName: "qa_toAllUsers",
  notificationType: null,
  notificationTemplate: "admin",
  targetPage: 1,
  notificationDescription: {
    shouldDisplay: false,
    title: "",
    body: "",
    media: {
      name: "",
      key: "",
      value: {
        mediaType: "",
        size: 1,
        width: 2,
        height: 3,
        url: "",
      },
    },
  },
};

export const TargetPages: IOptionProps[] = [
  { id: 1, title: "Home" },
  { id: 2, title: "Updates" },
  { id: 3, title: "Insights" },
  { id: 4, title: "Investment" },
  { id: 5, title: "Portfolio" },
  { id: 6, title: "Promise" },
  { id: 7, title: "Profile" },
  { id: 8, title: "Refer Now" },
];

export const notificationBreadcrumbRoutes = (isViewMode?: any) => {
  return [
    {
      name: "Notification Management",
      route: RouteConstants.notificationManagementDashboard,
    },
    {
      name: "Notification List",
      route: RouteConstants.notificationList,
    },
    {
      name: isViewMode === undefined ? "Create Notification" : `Notifications`,
      route: RouteConstants.notificationCreation,
    },
  ];
};
