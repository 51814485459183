import {
  Box,
  Checkbox,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  FormFieldContainer,
  InfoComponent,
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { Fragment, useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PageHeading from "components/page-heading/PageHeading";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import {
  newNotificationPayload,
  notificationBreadcrumbRoutes,
  TargetPages,
} from "./NotificationInitialValues";
import { FormikValues, useFormik, getIn } from "formik";
import { notificationValidationSchema } from "./NotificationValidations";
import NotificationActions from "redux-container/notification-management/NotificationsRedux";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import { RouteConstants } from "routes/RouteConstants";
import { notificationTypeEnum } from "enumerations/NotificationTypeEnum";
import { TooltipContentWrapper } from "./Notificiation.style";

const actionDispatch = (dispatch: any) => {
  return {
    addNotificationRequest: (data: any) =>
      dispatch(NotificationActions.addNotificationRequest(data)),
    setNotificationsReduxState: (key: string, value: any) =>
      dispatch(NotificationActions.setNotificationsState(key, value)),
    fileUploadRequest: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    setUploadFileState: (key: string, value: any) =>
      dispatch(FileUploadActions.setUploadFileState(key, value)),
  };
};

const CreateNotification = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const {
    addNotificationRequest,
    fileUploadRequest,
    setUploadFileState,
    setNotificationsReduxState,
  } = actionDispatch(useDispatch());

  const [notificationState, setNotificationState] = useState({
    isViewMode: location?.state?.isViewMode,
    saveButtonText: "Publish",
    appNotify: false,
    pushNotify: false,
  });

  const {
    errorMessage,
    successMessage,
    isSuccess,
    isLoading,
    data,
    error,
    imageDetails,
    isFileLoading,
    isUploadSuccess,
    shouldFlashMessageOpen,
    confirmDialogOpen,
    isDialogOpen,
  } = useSelector((state: any) => ({
    successMessage: state?.notifications?.message,
    errorMessage: state?.notifications?.error,
    confirmDialogOpen: state?.notifications?.confirmDialogOpen,
    isSuccess: state?.notifications?.isSuccess,
    isDialogOpen: state?.notifications?.isDialogOpen,
    isLoading: state?.notifications?.isLoading,
    shouldFlashMessageOpen: state?.fileUpload?.shouldFlashMessageOpen,
    data: state?.fileUpload?.data,
    error: state?.fileUpload?.error,
    isUploadSuccess: state?.fileUpload?.isSuccess,
    imageDetails: state?.fileUpload?.imageDetails,
    isFileLoading: state?.fileUpload?.isLoading,
  }));

  const handleNotifyOptions = (event: any) => {
    const updateNotificationState = {
      ...notificationState,
      [event.target.name]: event.target.checked,
    };
    setNotificationState(updateNotificationState);
    const { appNotify, pushNotify } = updateNotificationState;
    if (appNotify && pushNotify === false) {
      return setFieldValue(
        "notificationType",
        notificationTypeEnum.APP_NOTIFICATION
      );
    }
    if (pushNotify && appNotify === false) {
      return setFieldValue(
        "notificationType",
        notificationTypeEnum.PUSH_NOTIFICATION
      );
    }
    if (appNotify && pushNotify) {
      return setFieldValue(
        "notificationType",
        notificationTypeEnum.APP_NOTIFICATION_PUSH_NOTIFICATION
      );
    }
  };

  //Post API call to add Notifications.
  const handlePublishClick = (formValues: FormikValues) => {
    if (!formValues.notificationDescription?.shouldDisplay) {
      delete formValues.notificationDescription.media;
    }
    addNotificationRequest(formValues);
    setNotificationsReduxState("confirmDialogOpen", false);
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const onSubmitHandler = () => {
    setNotificationsReduxState("confirmDialogOpen", true);
  };

  //handle change for Image Uploader with API call.
  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (e: any) => {
        data.binary = e.target.result;
        fileUploadRequest(data);
      };
      reader.readAsArrayBuffer(file);
      setFieldValue(
        `notificationDescription.media.value.url`,
        imageDetails?.url
      );
      setFieldValue(`notificationDescription.media.value.mediaType`, file.type);
      setFieldValue(`notificationDescription.media.value.size`, file.size);
      setFieldValue(`notificationDescription.media.name`, file.name);
      setFieldValue(`notificationDescription.media.key`, file.name);
      setUploadFileState("shouldFlashMessageOpen", true);
    }
  };
  useEffect(() => {
    if (imageDetails) {
      setFieldValue(
        `notificationDescription.media.value.url`,
        imageDetails?.url
      );
      setUploadFileState("imageDetails", null);
    }
  }, [imageDetails]);

  const handleDialogClose = () => {
    setNotificationsReduxState("confirmDialogOpen", false);
    setNotificationsReduxState("error", null);
  };

  const handleImageDelete = () => {
    setFieldValue("notificationDescription.media.value.url", "");
  };

  const handleCloseFlashMessageDialog = () => {
    setNotificationsReduxState("error", null);
    setNotificationsReduxState("confirmDialogOpen", false);
    setNotificationsReduxState("isDialogOpen", false);
    if (isSuccess) {
      navigate(RouteConstants.notificationList);
    }
  };

  //using "useFormik" hook for state management,Validation and Submit User data(Post APi call) .
  //for validation "Yup" package is used .
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues:
      location.state.notification !== null
        ? location.state.notification
        : newNotificationPayload,
    validationSchema: notificationValidationSchema,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });

  return (
    <Fragment>
      <BreadCrumbs routes={notificationBreadcrumbRoutes(location.state)} />
      <PageHeading title="Notification Management" />
      <Container>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Stack mt={"1rem"}>
            <Stack>
              <IconButton edge={"start"} onClick={() => navigate(-1)}>
                <ChevronLeftIcon color="inherit" />
              </IconButton>
              <Typography
                color={"secondary.dark"}
                variant="h5"
                marginRight={"1.5rem"}
              >
                {location.state.notification !== null
                  ? "Notification Title"
                  : "Create New"}
              </Typography>
              <InfoComponent
                infoContent={
                  <TooltipContentWrapper>
                    <Typography
                      variant="caption"
                      mb={"1rem"}
                      className={"content-caption content-text"}
                    >
                      Notifications will appear on the app in the following
                      sequence:
                    </Typography>
                    <Typography
                      className={"content-text order-list-gutter"}
                      variant="caption"
                      component={"ol"}
                    >
                      <Typography
                        variant="caption"
                        className={"content-text"}
                        component={"li"}
                      >
                        Title
                      </Typography>
                      <Typography
                        variant="caption"
                        className={"content-text"}
                        component={"li"}
                      >
                        Notification message
                      </Typography>
                      <Typography
                        variant="caption"
                        className={"content-text"}
                        component={"li"}
                      >
                        Image
                      </Typography>
                    </Typography>
                  </TooltipContentWrapper>
                }
              />
            </Stack>
            <Stack direction={"row"} columnGap={2}>
              <Collapse in={!notificationState.isViewMode}>
                <ButtonComponent
                  type="reset"
                  color="inherit"
                  onClick={handleCancelClick}
                >
                  Cancel
                </ButtonComponent>
              </Collapse>
              <Collapse
                in={!notificationState.isViewMode}
                orientation={"horizontal"}
              >
                <LoadingButtonComponent
                  type="submit"
                  loading={isLoading}
                  disabled={!isValid || !dirty}
                >
                  {notificationState.saveButtonText}
                </LoadingButtonComponent>
              </Collapse>
            </Stack>
          </Stack>
          <FormFieldContainer>
            <Grid
              container
              alignItems={"flex-end"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12} sm={12} lg={7} xl={8}>
                <Grid container spacing={4} justifyContent={"space-between"}>
                  <Grid item lg={6} xl={6}>
                    <TextFieldComponent
                      name={`notificationDescription.title`}
                      placeholder="Enter"
                      label="Notification Title *"
                      value={values.notificationDescription?.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 20,
                      }}
                      InputProps={{
                        readOnly: notificationState.isViewMode,
                      }}
                      fieldhelpertext={
                        getIn(touched, "notificationDescription.title") &&
                        getIn(errors, "notificationDescription.title")
                      }
                      error={Boolean(
                        getIn(touched, "notificationDescription.title") &&
                          getIn(errors, "notificationDescription.title")
                      )}
                      disabled={notificationState.isViewMode}
                    />
                  </Grid>
                  <Grid item lg={6} xl={6}>
                    <TextFieldComponent
                      select
                      label="Redirect Page*"
                      name="targetPage"
                      onBlur={handleBlur}
                      value={values.targetPage}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: notificationState.isViewMode,
                      }}
                      error={Boolean(errors?.targetPage && touched.targetPage)}
                      fieldhelpertext={
                        errors.targetPage &&
                        touched.targetPage &&
                        errors.targetPage
                      }
                      disabled={notificationState.isViewMode}
                    >
                      {TargetPages.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </TextFieldComponent>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      multiline
                      rows={3}
                      name={`notificationDescription.body`}
                      placeholder="Enter"
                      label="Notification Message *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.notificationDescription?.body}
                      error={Boolean(
                        getIn(touched, "notificationDescription.body") &&
                          getIn(errors, "notificationDescription.body")
                      )}
                      fieldhelpertext={
                        getIn(touched, "notificationDescription.body") &&
                        getIn(errors, "notificationDescription.body")
                      }
                      InputProps={{
                        readOnly: notificationState.isViewMode,
                      }}
                      inputProps={{
                        maxLength: 110,
                      }}
                      disabled={notificationState.isViewMode}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} lg={3} xl={3} justifyContent="flex-end">
                <Stack mb={"0.5rem"} justifyContent={"flex-start"}>
                  <FormControlLabel
                    sx={{
                      marginLeft: 0,
                      marginRight: "1rem",
                      columnGap: "1rem",
                    }}
                    name={"notificationDescription.shouldDisplay"}
                    checked={values.notificationDescription?.shouldDisplay}
                    onChange={handleChange}
                    control={<SwitchComponent />}
                    label="Upload Media"
                    labelPlacement="start"
                    disabled={notificationState.isViewMode}
                  />
                  <InfoComponent
                    infoContent={
                      <TooltipContentWrapper>
                        <Typography
                          variant="caption"
                          mb={"1rem"}
                          className={"content-caption content-text"}
                        >
                          Toggle is to show/hide Notification image on the
                          Customer App.
                        </Typography>
                      </TooltipContentWrapper>
                    }
                  />
                </Stack>
                <MediaUploadComponent
                  name={`notificationDescription.media`}
                  disabled={
                    !values.notificationDescription?.shouldDisplay ||
                    notificationState.isViewMode
                  }
                  loading={isFileLoading}
                  onChange={handleFileInputChange}
                  previewimage={
                    values?.notificationDescription?.media?.value?.url
                  }
                  cancelimage={handleImageDelete}
                  fieldhelpertext={
                    getIn(touched, `notificationDescription.media.value.url`) &&
                    getIn(errors, `notificationDescription.media.value.url`)
                  }
                />
              </Grid>
            </Grid>
          </FormFieldContainer>
          <ConfirmationDialog
            shouldOpen={confirmDialogOpen}
            isOkDisabled={
              !notificationState.appNotify && !notificationState.pushNotify
            }
            notifycheckoptions={
              <Stack marginTop={"1rem"} justifyContent={"space-evenly"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="appNotify"
                      checked={notificationState.appNotify}
                      onChange={handleNotifyOptions}
                    />
                  }
                  label="Notify as In - app Notification"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="pushNotify"
                      checked={notificationState.pushNotify}
                      onChange={handleNotifyOptions}
                    />
                  }
                  label="Notify as Push Notification"
                />
              </Stack>
            }
            content="Are you sure you want to Publish?"
            okText="Publish"
            okHandler={() => handlePublishClick(values)}
            cancelHandler={handleDialogClose}
          />
          <FlashMessageDialog
            shouldOpen={isDialogOpen}
            content={successMessage ? successMessage : errorMessage}
            isSuccess={isSuccess}
            cancelHandler={handleCloseFlashMessageDialog}
          />
        </Box>
      </Container>
    </Fragment>
  );
};

export default CreateNotification;
