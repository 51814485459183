import ApiService from "services/ApiService";
import { takeEvery } from "redux-saga/effects";

import { LoginTypes } from "redux-container/login/LoginRedux";
import { login } from "./login/LoginSaga";

import {
  getPage3Promise,
  updatePage3Promise,
  addPage3Promise,
} from "./page-management/Page3PromiseSaga";
import { PagePromisesTypes } from "redux-container/page-management/Page3PromiseRedux";

import { ResetPasswordTypes } from "redux-container/reset-password/ResetPasswordRedux";
import { resetPassword } from "./reset-password/ResetPasswordSaga";

import { ForgetPasswordTypes } from "redux-container/forgot-password/ForgotPasswordRedux";
import { forgotPasswordEmailSent } from "./forgot-password/ForgotPasswordSaga";

import { UserTypes } from "redux-container/users/UsersRedux";
import { faqTypes } from "redux-container/faq/FaqRedux";
import { UserLogsTypes } from "redux-container/users/UserLogsRedux";
import {
  logout,
  getUsers,
  getUserLogs,
  getUserDashboard,
  updateAdminStatus,
  updateUsers,
  inviteUsers,
  getUserModules,
  getUserRoles,
  deleteUsers,
  getAllUsers,
  deleteUser,
  updateUserStatus,
  adminListForFilters,
} from "./users/UsersSaga";

import { PageDashboardTypes } from "redux-container/page-management/PageDashboardRedux";
import { getPageDashboard } from "./page-management/PageDashboardSaga";

import { ChangePasswordTypes } from "redux-container/change-password/ChangePasswordRedux";
import { changePassword } from "./change-password/ChangePasswordSaga";

import {
  getPromisesList,
  getPromisesCopyList,
  updatePromiseStatus,
  promiseStatus,
  updatePromisePriority,
  bulkDeletePromise,
} from "./promises/promisesListSaga";
import { PromisesListTypes } from "redux-container/promises/PromisesListRedux";
import { getPromiseDashboard } from "./promises/promiseSaga";
import { PromiseTypes } from "redux-container/promises/PromiseRedux";

import { getReferrals } from "./referrals/ReferralsSaga";
import { ReferralsTypes } from "redux-container/referrals/ReferralsRedux";
import {
  deleteFaq,
  getFaqs,
  updateFaq,
  updateFaqPriority,
} from "sagas/faq/FaqSaga";
import { InviteUserTypes } from "redux-container/users/InviteUserRedux";
import { RoleAndModuleTypes } from "redux-container/users/RolesAndModulesRedux";
import { PromisesCreationTypes } from "redux-container/promises/PromisesCreationRedux";
import {
  changePromisesStatus,
  getPromises,
  postPromises,
  postPromisesDraft,
  removePromises,
  updatePromises,
  updatePromisesDraft,
} from "./promises/PromisesCreationSaga";
import {
  getResourceDashboard,
  getCategories,
} from "./resource-management/ResourceDashboardSaga";
import { ResourceDashboardTypes } from "redux-container/resource-management/ResourceDashboardRedux";
import { CategoriesTypes } from "redux-container/resource-management/CategoryRedux";
import { projectListTypes } from "redux-container/project-management/ProjectListRedux";
import { UpCommingProjectContentCreationTypes } from "redux-container/project-management/upcomming-project/UpcommingProjectRedux";
import { leadTypes } from "redux-container/lead-management/LeadRedux";

import {
  deleteSelectedProjects,
  getProjectDraftsList,
  getProjectList,
  updateProjectPriority,
  updateProjectsStatus,
  addProjectDraft,
  updateProjectDraft,
  deleteProjectDraft,
} from "./project-management/ProjectListSaga";

import {
  getTestimonials,
  addTestimonial,
  updateTestimonial,
  deleteTestimonial,
  changeTestimonialStatus,
  updateTestimonialPriority,
} from "./marketing-management/testimonials/TestimonialsSaga";
import { TestimonialsTypes } from "redux-container/marketing-management/testimonials/TestimonialsRedux";
import { MarketingDashboardTypes } from "redux-container/marketing-management/MarketingDashboardRedux";
import { getMarketingDashboard } from "./marketing-management/MarketingDashboardSaga";
import { LandingPageDashboardTypes } from "redux-container/landing-page-management/LandingPageDashboardRedux";
import { getLandingPageDashboard } from "./landing-page-management/LandingPageDashboardSaga";
import { UpdateTypes } from "redux-container/marketing-management/updates/UpdatesRedux";
import { BlogTypes } from "redux-container/blog-management/BlogRedux";
import {
  getUpdates,
  deleteUpdate,
  // bulkUpdateDelete,
  updateStatus,
  updatePriority,
  updateCreationStatus,
} from "sagas/marketing-management/updates/UpdatesSaga";
import {
  getBlogs,
  blogStatus,
  blogPriority,
  deleteBlog,
  blogCreationStatus,
  addBlogs,
  addDraftsBlogs,
  getSingleBlog,
  updateBlogs,
  deleteSingleBlog,
} from "sagas/blog-management/BlogSaga";

import { GenealFaqTypes } from "../redux-container/faq/GeneralFaqRedux";
import {
  allFaqs,
  faqBulkDelete,
  faqBulkUpdate,
  faqCreation,
  faqDelete,
  faqUpdate,
  faqUpdatePriority,
} from "../sagas/faq/GeneralFaqSaga";
import { GetAllCategoriesTypes } from "./../redux-container/faq/GetAllCategoriesRedux";
import {
  getAllCategories,
  addNewCategory,
  deleteCategory,
  putCategory,
  bulkStatusCategory,
} from "./faq/GetAllCategoriesSaga";
import { InsightsCreationTypes } from "redux-container/insights-creation/InsightsCreationRedux";
import {
  allInsightsCreation,
  getByIdInsights,
  insightsCreation,
  insightsCreationDelete,
  insightsCreationUpdate,
  insightsCreationUpdateStatus,
} from "./insights-creation/InsightsCreationSaga";
import { fileDelete, fileUpload } from "./file-upload-S3/fileUploadSaga";

import { UpdateCreationTypes } from "redux-container/marketing-management/updates/UpdateCreationRedux";
import {
  BlogCreationTypes,
} from "redux-container/blog-management/BlogCreationRedux";
import {
  addUpdates,
  addDraftsUpdates,
  getSingleUpdate,
  updateMarketingUpdates,
  deleteUpdates,
} from "./marketing-management/updates/UpdatesSaga";

import { FileUploadTypes } from "redux-container/file-upload-S3/fileUploadRedux";
// import { fileUpload } from "./file-uploadsaga/fileUploadSaga";

import { InsightsTypes } from "redux-container/insights/InsightsRedux";
import {
  deleteSelectedInsights,
  getInsights,
  updateInsightStatus,
  updateInsightPriority,
} from "./insights/InsightsSaga";

import { FaqListTypes } from "redux-container/resource-management/FaqListRedux";
import {
  getFaqList,
  deleteFaqs,
  changeFaqsStatus,
  changeFaqPriority,
} from "./resource-management/FaqListSaga";
import { PageManagementCreationTypes } from "redux-container/page-management/PageManagementRedux";
import {
  addPageManagementDataSaga,
  updatePageManagementDataSaga,
  getPageManagementDataSaga,
} from "./page-management/PageManagementSaga";
import { projectListHoablPage1Types } from "redux-container/page-management/hoabl-page-1/ProjectListInHoablPage1Redux";
import { getProjectContentList } from "./page-management/hoabl-page-1/ProjectListInHoablPage1Saga";
import { hoablPage1Types } from "redux-container/page-management/hoabl-page-1/HoablPage1Redux";
import {
  createHoablPage1,
  getHoablPage1,
  getProjectListHoablPage1,
  updateHoablPage1,
} from "./page-management/hoabl-page-1/HoablPage1Saga";
import {
  addPortfolioPage,
  getPortfolioPage,
  getProjectContents,
  updatePortfolioPage,
} from "./page-management-saga/pagePortfolioSaga";
import { portfolioPageTypes } from "redux-container/page-management/PagePortfolioRedux";
import { projectContentsTypes } from "redux-container/page-management/ProjectContentsRedux";
import { ProjectContentCreationTypes } from "redux-container/project-management/project-content/ProjectContentRedux";
import { ProjectAllFaqsListTypes } from "redux-container/project-management/project-content/ProjectAllFaqsListRedux";
import { getProjectAllFaqsListDataSaga } from "sagas/project-management/project-content-creation/ProjectAllFaqsList";

import {
  getProjectContentCreationDataSaga,
  updateProjectContentCreationDataSaga,
  addProjectContentCreationDataSaga,
  deleteProjectContentMappedFaq,
  getProjectContentToCloneSaga,
} from "sagas/project-management/project-content-creation/ProjectContentCreation";
import {
  addUpCommingProjectContentCreationDataSaga,
  getUpcommingProjectSaga,
  getUpcommingSingleProjectSaga,
  updateStatusUpcommingSingleProjectSaga,
  updateUpcommingSingleProjectSaga,
  filterUpCommingProjects,
} from "../sagas/project-management/upcomming-project-saga/UpCommingProjectSaga";
import { launchPhasesTypes } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import {
  getAllLaunchPhases,
  getLaunchPhases,
  verifyLauchPhase,
} from "./project-management/launch-phases-saga/projectLaunchPhasesSaga";
import { GetProjectCategoriesTypes } from "redux-container/project-management/ProjectCategoryRedux";
import {
  bulkDeleteProjectCategory,
  changeCategoryPriority,
  getProjectCategories,
  statusUpdateProjectCategory,
} from "./project-management/ProjectCategorySaga";
import { ProjectFaqTypes } from "redux-container/project-management/ProjectFaqsRedux";
import {
  getProjectFaqs,
  ProjectFaqBulkDelete,
  projectFaqBulkUpdate,
  projectFaqCreation,
  projectFaqsDelete,
  ProjectFaqUpdate,
  projectFaqUpdatePriority,
} from "./project-management/ProjectFaqsSaga";
import {
  addNewProjectCategoryForFaq,
  deleteProjectCategoryForFaq,
  projectCategoryForFaq,
  putProjectCategoryForFaq,
  statusUpdateProjectCategoryForFaq,
} from "./project-management/ProjectCategoryForFaqCreationsSaga";
import { projectCategoryForFaqCreationTypes } from "redux-container/project-management/ProjectCategoryForFaqCreationRedux";
import { AllProjectFaqsType } from "redux-container/project-management/ProjectAllFaqsRedux";
import { allProjectFaqs } from "./project-management/ProjectAllFaqsSaga";

import { ProjectCollectionCreationTypes } from "redux-container/project-management/project-collection/ProjectCollectionCreationRedux";
import {
  projectCollectionCreation,
  getProjectCollectionById,
  deleteProjectCollectionById,
  updateProjectCollectionById,
} from "./project-management/project-collection/ProjectCollectionCreationSaga";
import { ProjectCollectionListTypes } from "redux-container/project-management/project-collection/ProjectCollectionListRedux";
import {
  getProjectCollectionList,
  deleteProjectCollectionList,
  changeProjectCollectionListStatus,
} from "./project-management/project-collection/ProjectCollectionListSaga";

import {
  getNotifications,
  addNotification,
  updateNotification,
  changeNotificationStatus,
} from "./notification-management/NotificationsSaga";
import { NotificationsTypes } from "redux-container/notification-management/NotificationsRedux";
import { NotificationTypes } from "redux-container/notification-management/NotificationDashboardRedux";
import { getNotificationDashboard } from "./notification-management/NotificationDashboardSaga";
import { chatManagementTypes } from "redux-container/chat-management/ChatManagementRedux";
import {
  updateChatManagement,
  getChatManagement,
  addChatManagement,
  chatManagementList,
} from "./chat-management/ChatManagementSaga";
import { chatMangementListTypes } from "redux-container/chat-management/ChatManagementListRedux";
import { customerManagementTypes } from "redux-container/customer-management/CustomerManagementRedux";
import {
  blockedCustomerListSaga,
  customerFeedbackListData,
  unblockedCustomersSaga,
  getCustomerBookingJourney,
} from "./customer-management/CustomerManagementSaga";
import { CustomersTypes } from "redux-container/customer-management/CustomersListAndDetailsRedux";
import {
  getCustomerDocument,
  getCustomerInvestmentSummary,
  getCustomerOverviewSummary,
  getCustomers,
  getCustomerWatchlist,
  getCustomerInvestmentDetails,
} from "./customer-management/CustomerListAndDetailsSaga";
import { blockedCustomerListTypes } from "redux-container/customer-management/BlockedCustomerListRedux";
import { AuditLogTypes } from "redux-container/audit-log/auditLogRedux";
import { getAuditsSaga, postAuditsSaga } from "./audit-log/AuditLogSaga";
import { projectDraftTypes } from "redux-container/project-management/project-drafts/ProjectDraftsRedux";
import { LandingPageUrlCreationTypes } from "redux-container/landing-page-management/LandingPageUrlCreationRedux";
import {
  addLandingPageUrlCreation,
  changeUrlStatus,
  deleteUrl,
  getUrls,
} from "./landing-page-management/LandingPageUrlCreationSaga";
import { crmProjectUpdatesTypes } from "redux-container/project-management/crm-updates/CrmUpdates";
import { getCrmFetchDetails } from "./project-management/crm-updates/CrmUpdatesSaga";
import {
  deleteFormsSaga,
  getFormsListSaga,
  getLaunchesListSaga,
  getProjectsListSaga,
  updateFromStatusSaga,
} from "./form-list/FormListSaga";
import { FormsListTypes } from "redux-container/form-list/FormListRedux";
import { PaymentFormTypes } from "redux-container/payment-forms/paymentFormRedux";
import {
  createPaymentDetailSaga,
  getPaymentDetailSaga,
  getPaymentInventoriesSaga,
  updatePaymentDetailSaga,
} from "./payment-form-saga/PaymentFormSaga";
import { getLeaddata } from "./lead-management/LeadSaga";
import { MediaAndPrsTypes } from "redux-container/marketing-management/media-and-prs/MediaAndPrRedux";
import { addMediaAndPr, changeMediaAndPrsStatus, deleteMediaAndPrs, getMediaAndPrs, updateMediaAndPr, updateMediaAndPrsPriority } from "./marketing-management/media-and-prs/MediaAndPrsSaga";
import { AppUpdateTypes } from "redux-container/notification-management/AppUpdatesRedux";
import { addAppUpdate, getAppUpdates } from "./notification-management/AppUpdatesSaga";

const api = ApiService.create();

export default function* root() {
  yield takeEvery(LoginTypes.LOGIN_REQUEST, login, api);
  yield takeEvery(
    ChangePasswordTypes.CHANGE_PASSWORD_REQUEST,
    changePassword,
    api
  );
  yield takeEvery(
    ForgetPasswordTypes.FORGOT_PASSWORD_EMAIL_SENT_REQUEST,
    forgotPasswordEmailSent,
    api
  );
  yield takeEvery(
    ResetPasswordTypes.RESET_PASSWORD_REQUEST,
    resetPassword,
    api
  );
  yield takeEvery(UserTypes.LOGOUT_REQUEST, logout, api);
  yield takeEvery(UserTypes.GET_USERS_LIST_REQUEST, getUsers, api);
  yield takeEvery(UserTypes.GET_USER_DASHBOARD_REQUEST, getUserDashboard, api);
  yield takeEvery(
    UserTypes.GET_ADMIN_LIST_FOR_FILTERS_REQUEST,
    adminListForFilters,
    api
  );
  yield takeEvery(UserTypes.DELETE_USERS_REQUEST, deleteUsers, api);
  yield takeEvery(UserTypes.CHANGE_USER_STATUS_REQUEST, updateAdminStatus, api);

  yield takeEvery(UserLogsTypes.USER_LOGS_REQUEST, getUserLogs, api);

  yield takeEvery(
    PagePromisesTypes.GET_PAGE_PROMISES_REQUEST,
    getPage3Promise,
    api
  );
  yield takeEvery(
    PagePromisesTypes.ADD_PAGE_PROMISES_REQUEST,
    addPage3Promise,
    api
  );
  yield takeEvery(
    PagePromisesTypes.UPDATE_PAGE_PROMISES_REQUEST,
    updatePage3Promise,
    api
  );

  yield takeEvery(
    PromisesListTypes.PROMISES_LIST_REQUEST,
    getPromisesList,
    api
  );
  yield takeEvery(
    PromisesListTypes.PROMISES_LIST_COPY_REQUEST,
    getPromisesCopyList,
    api
  );
  yield takeEvery(
    PromiseTypes.GET_PROMISE_DASHBOARD_REQUEST,
    getPromiseDashboard,
    api
  );

  yield takeEvery(
    PromisesListTypes.UPDATE_PROMISE_STATUS_REQUEST,
    updatePromiseStatus,
    api
  );

  yield takeEvery(PromisesListTypes.PROMISE_STATUS_REQUEST, promiseStatus, api);
  yield takeEvery(
    PromisesListTypes.UPDATE_PROMISE_PRIORITY_REQUEST,
    updatePromisePriority,
    api
  );
  yield takeEvery(
    PromisesListTypes.BULK_DELETE_PROMISE_REQUEST,
    bulkDeletePromise,
    api
  );

  yield takeEvery(ReferralsTypes.REFERRALS_REQUEST, getReferrals, api);
  yield takeEvery(
    RoleAndModuleTypes.ROLES_AND_MODULES_REQUEST,
    getUserModules,
    api
  );
  yield takeEvery(
    RoleAndModuleTypes.ROLES_AND_MODULES_REQUEST,
    getUserRoles,
    api
  );
  yield takeEvery(InviteUserTypes.UPDATE_USERS_REQUEST, updateUsers, api);
  yield takeEvery(InviteUserTypes.INVITE_USERS_REQUEST, inviteUsers, api);
  yield takeEvery(InviteUserTypes.GET_USERS_REQUEST, getAllUsers, api);
  yield takeEvery(InviteUserTypes.DELETE_USER_REQUEST, deleteUser, api);
  yield takeEvery(
    InviteUserTypes.UPDATE_USER_STATUS_REQUEST,
    updateUserStatus,
    api
  );
  yield takeEvery(UserLogsTypes.USER_LOGS_REQUEST, getUserLogs, api);
  yield takeEvery(faqTypes.FAQ_REQUEST, getFaqs, api);
  yield takeEvery(faqTypes.DELETE_FAQ_REQUEST, deleteFaq, api);
  yield takeEvery(faqTypes.UPDATE_FAQ_STATUS_REQUEST, updateFaq, api);
  yield takeEvery(faqTypes.UPDATE_FAQ_PRIORITY_REQUEST, updateFaqPriority, api);

  /// General Faqs
  yield takeEvery(GenealFaqTypes.GET_ALL_FAQS_REQUEST, allFaqs, api);
  yield takeEvery(GenealFaqTypes.FAQ_CREATION_REQUEST, faqCreation, api);
  yield takeEvery(GenealFaqTypes.FAQ_UPDATE_REQUEST, faqUpdate, api);
  yield takeEvery(GenealFaqTypes.FAQ_DELETE_REQUEST, faqDelete, api);
  yield takeEvery(GenealFaqTypes.FAQ_BULK_UPDATE_REQUEST, faqBulkUpdate, api);
  yield takeEvery(GenealFaqTypes.FAQ_BULK_DELETE_REQUEST, faqBulkDelete, api);
  yield takeEvery(
    GenealFaqTypes.FAQ_PRIORITY_UPDATE_REQUEST,
    faqUpdatePriority,
    api
  );

  yield takeEvery(
    GetAllCategoriesTypes.GET_ALL_CATEGORIES_REQUEST,
    getAllCategories,
    api
  );
  yield takeEvery(
    GetAllCategoriesTypes.ADD_CATEGORY_REQUEST,
    addNewCategory,
    api
  );
  yield takeEvery(
    GetAllCategoriesTypes.DELETE_CATEGORY_REQUEST,
    deleteCategory,
    api
  );
  yield takeEvery(GetAllCategoriesTypes.PUT_CATEGORY_REQUEST, putCategory, api);
  yield takeEvery(
    GetAllCategoriesTypes.BULK_STATUS_CATEGORY_REQUEST,
    bulkStatusCategory,
    api
  );

  //project Cretion category
  yield takeEvery(
    GetProjectCategoriesTypes.GET_PROJECT_CATEGORIES_REQUEST,
    getProjectCategories,
    api
  );
  yield takeEvery(
    GetProjectCategoriesTypes.BULK_STATUS_UPDATE_PROJECT_CATEGORY_REQUEST,
    statusUpdateProjectCategory,
    api
  );
  yield takeEvery(
    ProjectFaqTypes.GET_ALL_PROJECT_FAQS_REQUEST,
    getProjectFaqs,
    api
  );
  yield takeEvery(
    GetProjectCategoriesTypes.CHANGE_CATEGORY_PRIORITY_REQUEST,
    changeCategoryPriority,
    api
  );
  //ResourceManagement
  yield takeEvery(
    ResourceDashboardTypes.GET_RESOURCE_DASHBOARD_REQUEST,
    getResourceDashboard,
    api
  );
  yield takeEvery(CategoriesTypes.GET_CATEGORIES_REQUEST, getCategories, api);

  yield takeEvery(projectListTypes.PROJECT_LIST_REQUEST, getProjectList, api);
  yield takeEvery(
    projectDraftTypes.GET_PROJECT_DRAFT_REQUEST,
    getProjectDraftsList,
    api
  );
  yield takeEvery(
    projectDraftTypes.ADD_PROJECT_DRAFT_REQUEST,
    addProjectDraft,
    api
  );
  yield takeEvery(
    projectDraftTypes.UPDATE_PROJECT_DRAFT_REQUEST,
    updateProjectDraft,
    api
  );
  yield takeEvery(
    projectDraftTypes.DELETE_PROJECT_DRAFT_REQUEST,
    deleteProjectDraft,
    api
  );
  yield takeEvery(
    TestimonialsTypes.ADD_TESTIMONIAL_REQUEST,
    addTestimonial,
    api
  );

  // Promises creation saga
  yield takeEvery(
    PromisesCreationTypes.GET_ALL_PROMISES_REQUEST,
    getPromises,
    api
  );
  yield takeEvery(
    PromisesCreationTypes.PROMISES_CREATION_REQUEST,
    postPromises,
    api
  );
  yield takeEvery(
    PromisesCreationTypes.PROMISES_UPDATE_DRAFT_REQUEST,
    updatePromisesDraft,
    api
  );
  yield takeEvery(
    PromisesCreationTypes.PROMISES_UPDATE_REQUEST,
    updatePromises,
    api
  );
  yield takeEvery(
    PromisesCreationTypes.PROMISES_DRAFT_REQUEST,
    postPromisesDraft,
    api
  );
  yield takeEvery(
    PromisesCreationTypes.PROMISES_STATUS_REQUEST,
    changePromisesStatus,
    api
  );
  yield takeEvery(
    PromisesCreationTypes.REMOVE_PROMISES_REQUEST,
    removePromises,
    api
  );

  //Testimonial saga
  yield takeEvery(
    TestimonialsTypes.GET_TESTIMONIALS_REQUEST,
    getTestimonials,
    api
  );
  yield takeEvery(
    TestimonialsTypes.UPDATE_TESTIMONIAL_REQUEST,
    updateTestimonial,
    api
  );
  yield takeEvery(
    TestimonialsTypes.DELETE_TESTIMONIAL_REQUEST,
    deleteTestimonial,
    api
  );
  yield takeEvery(
    TestimonialsTypes.CHANGE_TESTIMONIAL_STATUS_REQUEST,
    changeTestimonialStatus,
    api
  );
  yield takeEvery(
    TestimonialsTypes.UPDATE_TESTIMONIAL_PRIORITY_REQUEST,
    updateTestimonialPriority,
    api
  );

  yield takeEvery(
    MarketingDashboardTypes.GET_MARKETING_DASHBOARD_REQUEST,
    getMarketingDashboard,
    api
  );

  yield takeEvery(
    PageDashboardTypes.GET_PAGE_DASHBOARD_REQUEST,
    getPageDashboard,
    api
  );

  yield takeEvery(
    InsightsCreationTypes.INSIGHTS_CREATION_REQUEST,
    insightsCreation,
    api
  );
  yield takeEvery(
    InsightsCreationTypes.GET_ALL_INSIGHTS_CREATION_REQUEST,
    allInsightsCreation,
    api
  );
  yield takeEvery(
    InsightsCreationTypes.INSIGHTS_CREATION_UPDATE_REQUEST,
    insightsCreationUpdate,
    api
  );
  yield takeEvery(
    InsightsCreationTypes.INSIGHTS_CREATION_DELETE_REQUEST,
    insightsCreationDelete,
    api
  );
  yield takeEvery(
    InsightsCreationTypes.INSIGHTS_CREATION_UPDATE_STATUS_REQUEST,
    insightsCreationUpdateStatus,
    api
  );
  yield takeEvery(
    InsightsCreationTypes.GET_INSIGHTS_BY_ID_REQUEST,
    getByIdInsights,
    api
  );

  // yield takeEvery(faqTypes.UPDATE_PRIORITY_REQUEST, updatePriority, api); // Check this

  yield takeEvery(UpdateTypes.UPDATE_REQUEST, getUpdates, api);
  yield takeEvery(UpdateTypes.DELETE_UPDATE_REQUEST, deleteUpdate, api);
  // yield takeEvery(UpdateTypes.DELETE_BULK_UPDATE_REQUEST, bulkUpdateDelete, api);
  yield takeEvery(UpdateTypes.UPDATE_STATUS_REQUEST, updateStatus, api);
  yield takeEvery(UpdateTypes.UPDATE_PRIORITY_REQUEST, updatePriority, api);

  yield takeEvery(BlogTypes.BLOG_REQUEST, getBlogs, api);
  yield takeEvery(BlogTypes.DELETE_BLOG_REQUEST, deleteBlog, api);
  yield takeEvery(BlogTypes.BLOG_STATUS_REQUEST, blogStatus, api);
  yield takeEvery(BlogTypes.BLOG_PRIORITY_REQUEST, blogPriority, api);

  yield takeEvery(UpdateCreationTypes.ADD_UPDATES_REQUEST, addUpdates, api);
  yield takeEvery(BlogCreationTypes.ADD_BLOGS_REQUEST, addBlogs, api);

  yield takeEvery(
    UpdateCreationTypes.ADD_DRAFTS_UPDATES_REQUEST,
    addDraftsUpdates,
    api
  );
  yield takeEvery(
    BlogCreationTypes.ADD_DRAFTS_BLOGS_REQUEST,
    addDraftsBlogs,
    api
  );
  yield takeEvery(
    UpdateCreationTypes.GET_SINGLE_UPDATES_REQUEST,
    getSingleUpdate,
    api
  );
  yield takeEvery(
    BlogCreationTypes.GET_SINGLE_BLOGS_REQUEST,
    getSingleBlog,
    api
  );
  yield takeEvery(
    UpdateCreationTypes.UPDATE_MARKETING_UPDATES_REQUEST,
    updateMarketingUpdates,
    api
  );
  yield takeEvery(BlogCreationTypes.UPDATE_BLOGS_REQUEST, updateBlogs, api);
  yield takeEvery(
    UpdateCreationTypes.DELETE_UPDATES_REQUEST,
    deleteUpdates,
    api
  );
  yield takeEvery(
    BlogCreationTypes.DELETE_BLOGS_REQUEST,
    deleteSingleBlog,
    api
  );
  yield takeEvery(
    UpdateCreationTypes.UPDATE_CREATION_STATUS_REQUEST,
    updateCreationStatus,
    api
  );
  yield takeEvery(
    BlogCreationTypes.BLOG_CREATION_STATUS_REQUEST,
    blogCreationStatus,
    api
  );

  yield takeEvery(
    LandingPageDashboardTypes.GET_LANDING_PAGE_DASHBOARD_REQUEST,
    getLandingPageDashboard,
    api
  );
  yield takeEvery(
    LandingPageUrlCreationTypes.ADD_LANDING_PAGE_URL_CREATION_REQUEST,
    addLandingPageUrlCreation,
    api
  );

  yield takeEvery(LandingPageUrlCreationTypes.GET_URLS_REQUEST, getUrls, api);

  yield takeEvery(
    LandingPageUrlCreationTypes.CHANGE_URL_STATUS_REQUEST,
    changeUrlStatus,
    api
  );
  yield takeEvery(
    LandingPageUrlCreationTypes.DELETE_URL_REQUEST,
    deleteUrl,
    api
  );

  // File upload
  yield takeEvery(FileUploadTypes.FILE_UPLOAD_REQUEST, fileUpload, api);
  yield takeEvery(FileUploadTypes.FILE_DELETE_REQUEST, fileDelete, api);
  yield takeEvery(InsightsTypes.GET_INSIGHTS_REQUEST, getInsights, api);
  yield takeEvery(
    InsightsTypes.UPDATE_INSIGHT_STATUS_REQUEST,
    updateInsightStatus,
    api
  );
  yield takeEvery(
    InsightsTypes.DELETE_INSIGHTS_REQUEST,
    deleteSelectedInsights,
    api
  );
  yield takeEvery(
    InsightsTypes.UPDATE_INSIGHT_PRIORITY_REQUEST,
    updateInsightPriority,
    api
  );
  yield takeEvery(
    projectListTypes.UPDATE_PROJECT_PRIORITY_REQUEST,
    updateProjectPriority,
    api
  );
  yield takeEvery(
    projectListTypes.UPDATE_PROJECTS_STATUS_REQUEST,
    updateProjectsStatus,
    api
  );
  yield takeEvery(
    projectListTypes.DELETE_PROJECTS_REQUEST,
    deleteSelectedProjects,
    api
  );

  //FAQ List Sagas
  yield takeEvery(FaqListTypes.GET_FAQ_LIST_REQUEST, getFaqList, api);
  yield takeEvery(FaqListTypes.DELETE_FAQS_REQUEST, deleteFaqs, api);
  yield takeEvery(
    FaqListTypes.CHANGE_FAQS_STATUS_REQUEST,
    changeFaqsStatus,
    api
  );
  yield takeEvery(
    FaqListTypes.CHANGE_FAQ_PRIORITY_REQUEST,
    changeFaqPriority,
    api
  );

  //Pagemanagement
  yield takeEvery(
    PageManagementCreationTypes.ADD_PAGE_MANAGEMENT_DATA_REQUEST,
    addPageManagementDataSaga,
    api
  );

  yield takeEvery(
    PageManagementCreationTypes.UPDATE_PAGE_MANAGEMENT_DATA_REQUEST,
    updatePageManagementDataSaga,
    api
  );

  yield takeEvery(
    PageManagementCreationTypes.GET_PAGE_MANAGEMENT_DATA_REQUEST,
    getPageManagementDataSaga,
    api
  );

  // Payment Management

  yield takeEvery(
    PaymentFormTypes.CREATE_PAYMENT_FORM_REQUEST,
    createPaymentDetailSaga,
    api
  );
  yield takeEvery(
    PaymentFormTypes.GET_PAYMENT_FORM_REQUEST,
    getPaymentDetailSaga,
    api
  );

  yield takeEvery(
    PaymentFormTypes.GET_PAYMENT_INVENTORIES_REQUEST,
    getPaymentInventoriesSaga,
    api
  );
  yield takeEvery(
    PaymentFormTypes.UPDATE_PAYMENT_FORM_REQUEST,
    updatePaymentDetailSaga,
    api
  );

  // all Faqs
  yield takeEvery(
    AllProjectFaqsType.ALL_FAQS_PROJECT_REQUEST,
    allProjectFaqs,
    api
  );

  //for project category

  yield takeEvery(
    projectCategoryForFaqCreationTypes.PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST,
    projectCategoryForFaq,
    api
  );

  yield takeEvery(
    projectCategoryForFaqCreationTypes.ADD_PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST,
    addNewProjectCategoryForFaq,
    api
  );
  yield takeEvery(
    projectCategoryForFaqCreationTypes.DELETE_PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST,
    deleteProjectCategoryForFaq,
    api
  );
  yield takeEvery(
    projectCategoryForFaqCreationTypes.PUT_PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST,
    putProjectCategoryForFaq,
    api
  );

  yield takeEvery(
    projectCategoryForFaqCreationTypes.BULK_STATUS_PROJECT_CATEGORY_FOR_FAQ_CREATION_REQUEST,
    statusUpdateProjectCategoryForFaq,
    api
  );

  yield takeEvery(
    GetProjectCategoriesTypes.BULK_DELETE_PROJECT_CATEGORY_REQUEST,
    bulkDeleteProjectCategory,
    api
  );

  yield takeEvery(
    ProjectFaqTypes.PROJECT_FAQ_CREATION_REQUEST,
    projectFaqCreation,
    api
  );

  yield takeEvery(
    ProjectFaqTypes.PROJECT_FAQ_UPDATE_REQUEST,
    ProjectFaqUpdate,
    api
  );
  yield takeEvery(
    ProjectFaqTypes.PROJECT_FAQ_BULK_DELETE_REQUEST,
    ProjectFaqBulkDelete,
    api
  );
  yield takeEvery(
    ProjectFaqTypes.PROJECT_FAQ_BULK_UPDATE_REQUEST,
    projectFaqBulkUpdate,
    api
  );
  yield takeEvery(
    ProjectFaqTypes.PROJECT_FAQ_DELETE_REQUEST,
    projectFaqsDelete,
    api
  );
  yield takeEvery(
    ProjectFaqTypes.PROJECT_FAQ_BULK_UPDATE_REQUEST,
    projectFaqBulkUpdate,
    api
  );

  yield takeEvery(
    ProjectFaqTypes.PROJECT_FAQ_PRIORITY_UPDATE_REQUEST,
    projectFaqUpdatePriority,
    api
  );
  // yield takeEvery(ProjectCollectionListTypes.GET_PROJECT_COLLECTION_LIST_REQUEST, getProjectCollectionList, api);
  // yield takeEvery(ProjectCollectionListTypes.DELETE_PROJECT_COLLECTION_LIST_REQUEST, deleteProjectCollectionList, api);
  // yield takeEvery(ProjectCollectionListTypes.CHANGE_PROJECT_COLLECTION_LIST_STATUS_REQUEST, changeProjectCollectionListStatus, api);

  yield takeEvery(
    ProjectCollectionListTypes.GET_PROJECT_COLLECTION_LIST_REQUEST,
    getProjectCollectionList,
    api
  );
  yield takeEvery(
    ProjectCollectionListTypes.DELETE_PROJECT_COLLECTION_LIST_REQUEST,
    deleteProjectCollectionList,
    api
  );
  yield takeEvery(
    ProjectCollectionListTypes.CHANGE_PROJECT_COLLECTION_LIST_STATUS_REQUEST,
    changeProjectCollectionListStatus,
    api
  );

  yield takeEvery(
    ProjectCollectionCreationTypes.ADD_PROJECT_COLLECTION_REQUEST,
    projectCollectionCreation,
    api
  );
  yield takeEvery(
    ProjectCollectionCreationTypes.GET_PROJECT_COLLECTION_BY_ID_REQUEST,
    getProjectCollectionById,
    api
  );
  yield takeEvery(
    ProjectCollectionCreationTypes.DELETE_PROJECT_COLLECTION_BY_ID_REQUEST,
    deleteProjectCollectionById,
    api
  );
  yield takeEvery(
    ProjectCollectionCreationTypes.UPDATE_PROJECT_COLLECTION_BY_ID_REQUEST,
    updateProjectCollectionById,
    api
  );
  yield takeEvery(
    ProjectCollectionCreationTypes.CHANGE_PROJECT_COLLECTION_LIST_STATUS_REQUEST,
    changeProjectCollectionListStatus,
    api
  );

  //page management list
  yield takeEvery(
    projectListHoablPage1Types.PROJECT_LIST_HOABL_PAGE1_REQUEST,
    getProjectContentList,
    api
  );
  yield takeEvery(hoablPage1Types.HOABL_PAGE1_REQUEST, getHoablPage1, api);
  yield takeEvery(
    hoablPage1Types.UPDATE_HOABL_PAGE1_REQUEST,
    updateHoablPage1,
    api
  );
  yield takeEvery(
    hoablPage1Types.CREATE_HOABL_PAGE1_REQUEST,
    createHoablPage1,
    api
  );
  yield takeEvery(
    hoablPage1Types.PROJECT_LIST_HOABL_PAGE_REQUEST,
    getProjectListHoablPage1,
    api
  );
  yield takeEvery(
    launchPhasesTypes.GET_LAUNCH_PHASES_REQUEST,
    getLaunchPhases,
    api
  );
  yield takeEvery(
    launchPhasesTypes.GET_ALL_LAUNCH_PHASES_REQUEST,
    getAllLaunchPhases,
    api
  );

  yield takeEvery(
    launchPhasesTypes.VERIFY_LAUNCH_PHASE_DATA_REQUEST,
    verifyLauchPhase,
    api
  );

  yield takeEvery(
    crmProjectUpdatesTypes.GET_CRM_PROJECT_UPDATES_REQUEST,
    getCrmFetchDetails,
    api
  );

  //Page management saga
  yield takeEvery(
    portfolioPageTypes.GET_PORTFOLIO_PAGE_REQUEST,
    getPortfolioPage,
    api
  );
  yield takeEvery(
    portfolioPageTypes.UPDATE_PORTFOLIO_PAGE_REQUEST,
    updatePortfolioPage,
    api
  );
  yield takeEvery(
    portfolioPageTypes.ADD_PORTFOLIO_PAGE_REQUEST,
    addPortfolioPage,
    api
  );
  yield takeEvery(
    projectContentsTypes.GET_PROJECT_CONTENTS_REQUEST,
    getProjectContents,
    api
  );
  yield takeEvery(
    ProjectContentCreationTypes.GET_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    getProjectContentCreationDataSaga,
    api
  );
  yield takeEvery(
    UpCommingProjectContentCreationTypes.UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    addUpCommingProjectContentCreationDataSaga,
    api
  );

  yield takeEvery(
    UpCommingProjectContentCreationTypes.GET_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    getUpcommingProjectSaga,
    api
  );

  yield takeEvery(
    UpCommingProjectContentCreationTypes.GET_SINGLE_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    getUpcommingSingleProjectSaga,
    api
  );
  yield takeEvery(
    UpCommingProjectContentCreationTypes.UPDATE_STATUS_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    updateStatusUpcommingSingleProjectSaga,
    api
  );

  yield takeEvery(
    UpCommingProjectContentCreationTypes.UPDATE_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    updateUpcommingSingleProjectSaga,
    api
  );

  yield takeEvery(
    UpCommingProjectContentCreationTypes.FILTER_UP_COMMING_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    filterUpCommingProjects,
    api
  );

  // leads

  yield takeEvery(leadTypes.GET_LEAD_DATA_REQUEST, getLeaddata, api);

  yield takeEvery(
    ProjectContentCreationTypes.GET_PROJECT_CONTENT_TO_CLONE_REQUEST,
    getProjectContentToCloneSaga,
    api
  );
  yield takeEvery(
    ProjectContentCreationTypes.ADD_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    addProjectContentCreationDataSaga,
    api
  );
  yield takeEvery(
    ProjectContentCreationTypes.UPDATE_PROJECT_CONTENT_CREATION_DATA_REQUEST,
    updateProjectContentCreationDataSaga,
    api
  );

  yield takeEvery(
    ProjectContentCreationTypes.DELETE_MAPPED_PROJECT_CONTENT_FAQ_REQUEST,
    deleteProjectContentMappedFaq,
    api
  );

  yield takeEvery(
    ProjectAllFaqsListTypes.GET_PROJECT_ALL_FAQS_LIST_DATA_REQUEST,
    getProjectAllFaqsListDataSaga,
    api
  );

  // Notifications Sagas
  yield takeEvery(
    NotificationsTypes.GET_NOTIFICATIONS_REQUEST,
    getNotifications,
    api
  );
  yield takeEvery(
    NotificationsTypes.ADD_NOTIFICATION_REQUEST,
    addNotification,
    api
  );
  yield takeEvery(
    NotificationsTypes.UPDATE_NOTIFICATION_REQUEST,
    updateNotification,
    api
  );
  yield takeEvery(
    NotificationsTypes.CHANGE_NOTIFICATION_STATUS_REQUEST,
    changeNotificationStatus,
    api
  );
  yield takeEvery(
    NotificationTypes.GET_NOTIFICATION_DASHBOARD_REQUEST,
    getNotificationDashboard,
    api
  );

  //chat management
  yield takeEvery(
    chatManagementTypes.ADD_CHAT_MANAGEMENT_DATA_REQUEST,
    addChatManagement,
    api
  );

  yield takeEvery(
    chatManagementTypes.GET_CHAT_MANAGEMENT_DATA_REQUEST,
    getChatManagement,
    api
  );

  yield takeEvery(
    chatManagementTypes.UPDATE_CHAT_MANAGEMENT_DATA_REQUEST,
    updateChatManagement,
    api
  );

  yield takeEvery(
    chatMangementListTypes.CHAT_MANAGEMENT_LIST_REQUEST,
    chatManagementList,
    api
  );

  yield takeEvery(
    customerManagementTypes.CUSTOMER_FEEDBACK_LIST_REQUEST,
    customerFeedbackListData,
    api
  );

  yield takeEvery(
    customerManagementTypes.GET_CUSTOMER_BOOKING_JOURNEY_REQUEST,
    getCustomerBookingJourney,
    api
  );
  yield takeEvery(
    CustomersTypes.GET_CUSTOMER_WATCH_LIST_REQUEST,
    getCustomerWatchlist,
    api
  );
  yield takeEvery(
    CustomersTypes.GET_CUSTOMER_INVESTMENT_DETAILS_REQUEST,
    getCustomerInvestmentDetails,
    api
  );
  yield takeEvery(CustomersTypes.GET_CUSTOMERS_REQUEST, getCustomers, api);
  yield takeEvery(
    CustomersTypes.GET_CUSTOMER_INVESTMENT_SUMMARY_REQUEST,
    getCustomerInvestmentSummary,
    api
  );

  yield takeEvery(
    blockedCustomerListTypes.BLOCKED_CUSTOMER_LIST_REQUEST,
    blockedCustomerListSaga,
    api
  );

  yield takeEvery(
    blockedCustomerListTypes.UNBLOCKED_CUSTOMER_REQUEST,
    unblockedCustomersSaga,
    api
  );

  yield takeEvery(
    CustomersTypes.GET_CUSTOMER_OVERVIEW_REQUEST,
    getCustomerOverviewSummary,
    api
  );
  yield takeEvery(
    CustomersTypes.GET_CUSTOMER_DOCUMENT_REQUEST,
    getCustomerDocument,
    api
  );
  yield takeEvery(AuditLogTypes.POST_AUDIT_LOG_REQUEST, postAuditsSaga, api);
  yield takeEvery(AuditLogTypes.GET_AUDIT_LOG_REQUEST, getAuditsSaga, api);

  //Form Management
  yield takeEvery(FormsListTypes.FORMS_LIST_REQUEST, getFormsListSaga, api);
  yield takeEvery(
    FormsListTypes.GET_PROJECTS_REQUEST,
    getProjectsListSaga,
    api
  );
  yield takeEvery(
    FormsListTypes.GET_LAUNCHES_REQUEST,
    getLaunchesListSaga,
    api
  );
  yield takeEvery(
    FormsListTypes.UPDATE_FORM_STATUS_REQUEST,
    updateFromStatusSaga,
    api
  );
  yield takeEvery(FormsListTypes.DELETE_FORMS_REQUEST, deleteFormsSaga, api);
  //Media and Prs
  yield takeEvery(
    MediaAndPrsTypes.GET_MEDIA_AND_PRS_REQUEST,
    getMediaAndPrs,
    api
  );
  yield takeEvery(
    MediaAndPrsTypes.ADD_MEDIA_AND_PR_REQUEST,
    addMediaAndPr,
    api
  );
  yield takeEvery(
    MediaAndPrsTypes.UPDATE_MEDIA_AND_PR_REQUEST,
    updateMediaAndPr,
    api
  );
  yield takeEvery(
    MediaAndPrsTypes.DELETE_MEDIA_AND_PR_REQUEST,
    deleteMediaAndPrs,
    api
  );
  yield takeEvery(
    MediaAndPrsTypes.CHANGE_MEDIA_AND_PR_STATUS_REQUEST,
    changeMediaAndPrsStatus,
    api
  );
  yield takeEvery(
    MediaAndPrsTypes.UPDATE_MEDIA_AND_PR_PRIORITY_REQUEST,
    updateMediaAndPrsPriority,
    api
  );
  yield takeEvery(AppUpdateTypes.GET_APP_UPDATES_REQUEST, getAppUpdates, api);
  yield takeEvery(AppUpdateTypes.ADD_APP_UPDATE_REQUEST, addAppUpdate, api);
}
