import React from "react";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import {
  Stack,
  Typography,
  Container,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Link,
  Box,
  TextField,
  Divider,
  Grid,
  InputAdornment,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonComponent,
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ProjectContentSections } from "../ProjectContentSections";
import { upCommingValidationSchema } from "./UpcommingValidation";
// import { InitinalValueProjectContent } from "../InitinalValuesProjectContent";
import {
  IInitinalValueProjectContent,
  InitinalValueProjectContent,
} from "./UpCommingInitialValues";
import FormFields from "./FormFields";
import UpCommingProjectContentCreationTypes from "redux-container/project-management/upcomming-project/UpcommingProjectRedux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "components/breadcrumbs/Breadcrumbs";
import { RouteConstants } from "routes/RouteConstants";
import { state } from "utils/States";
import UpCommingMedia from "./UpCommingMedia";
import ProjectIcons from "../project-icons/ProjectIcons";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import CurrentInfraStory from "./upcommingProjectSections/CurrentInfraStory";
import UpcomingInfraStory from "./upcommingProjectSections/UpcomingInfraStory";
import { PromiseCreationContainer } from "pages/promise-management/promise-creation/PromiseCreation.style";
import TourismAround from "./upcommingProjectSections/TourismAround";
import KeyPillars from "./upcommingProjectSections/KeyPillars";
const actionDispatch = (dispatch: any) => {
  return {
    upCommingProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        UpCommingProjectContentCreationTypes.upCommingProjectContentCreationDataRequest(
          data
        )
      ),
    getSingleUpCommingProjectContentCreationDataRequest: (data: any) =>
      dispatch(
        UpCommingProjectContentCreationTypes.getSingleUpCommingProjectContentCreationDataRequest(
          data
        )
      ),
    setUpcomingProjectState: (key: string, data: boolean) =>
      dispatch(
        UpCommingProjectContentCreationTypes.setUpcomingProjectState(key, data)
      ),
  };
};

const UpcommingProject = () => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    React.useState<boolean>(false);

  const { openDraftFlashMessage, isSuccess } = useSelector((state: any) => ({
    openDraftFlashMessage: state?.upCommingProjects?.openFlashMessage,
    isSuccess: state?.upCommingProjects?.isSuccess,
  }));

  const openFlashMessage = useSelector(
    (state: any) => state?.upCommingProjects?.openFlashMessage
  );

  const message = useSelector(
    (state: any) => state?.upCommingProjects?.message
  );

  const error = useSelector((state: any) => state?.upCommingProjects?.error);

  console.log("aaaaaa", openFlashMessage, openDraftFlashMessage, isSuccess);

  const {
    upCommingProjectContentCreationDataRequest,
    setUpcomingProjectState,
  } = actionDispatch(useDispatch());
  const navigate = useNavigate();
  const location: any = useLocation();

  const initialValues = {
    title: "",
    location: "",
    description: "",
    ProjectPhoneNumber: "",
    projectIcon: {
      name: "",
      key: "",
      value: {
        url: "",
        size: "",
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
    bannerImage: {
      name: "",
      key: "",
      value: {
        url: "",
        size: "",
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
  };

  const breadcrumbRoutes: any = [
    {
      name: "Project Management",
      route: RouteConstants.projectManagementDashboard,
    },
    {
      name: "Projects",
      route: RouteConstants.projectList,
    },
    {
      name: "Upcoming Projects",
      route: RouteConstants.upCommingProject,
    },
  ];

  const handleOnSave = (values: any) => {
    setIsConfirmDialogOpen(false);
    console.log("wwww", values);
    upCommingProjectContentCreationDataRequest(values);
    // navigate("/projects");
  };
  const projectFormikValues = () => {};

  const handleCloseFlashMessageDialog = () => {
    setUpcomingProjectState("openFlashMessage", false);
    navigate(-1);
  };

  return (
    <>
      <Formik
        initialValues={InitinalValueProjectContent}
        validationSchema={upCommingValidationSchema}
        onSubmit={handleOnSave}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
          handleBlur,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form noValidate>
            <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>
            <Container className="main-container">
              <ConfirmationDialog
                shouldOpen={isConfirmDialogOpen}
                content="Are you sure you want to Publish?"
                okText="Publish"
                okHandler={() => handleOnSave(values)}
                cancelHandler={() => setIsConfirmDialogOpen(false)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // marginInline: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    edge={"start"}
                    onClick={() => navigate(RouteConstants.projectList)}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <Typography variant="h5">Upcoming Projects</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ButtonComponent
                    onClick={() => navigate(RouteConstants.projectList)}
                  >
                    Cancel
                  </ButtonComponent>
                  {console.log("values", values)}
                  <LoadingButtonComponent
                    onClick={() => setIsConfirmDialogOpen(true)}
                    // type={ "submit" }
                    disabled={
                      values?.launchName === "" ||
                      values?.projectPhoneNumber === "" ||
                      values?.address?.city === "" ||
                      values?.address?.country === "" ||
                      values?.address?.state === "" ||
                      values?.shortDescription === "" ||
                      values?.bannerImage?.value?.url === "" ||
                      values?.projectIcon?.value?.url === "" ||
                      !isValid ||
                      !dirty
                    }
                    style={{ marginLeft: "1rem" }}
                  >
                    Publish
                  </LoadingButtonComponent>
                </div>
              </div>
              <>
                <PromiseCreationContainer sx={{ padding: 3 }}>
                  <TextFieldComponent
                    label={"Title"}
                    name={"launchName"}
                    onChange={handleChange}
                    style={{ marginTop: "4px" }}
                    required
                    value={values?.launchName}
                    error={
                      touched?.launchName && !values.launchName ? true : false
                    }
                    fieldhelpertext={
                      touched.launchName && !values.launchName && "Required"
                    }
                  />
                  <TextFieldComponent
                    fullWidth
                    required
                    name="shortDescription"
                    placeholder="short description *"
                    onChange={handleChange}
                  />
                  <TextFieldComponent
                    fullWidth
                    label={"ProjectPhoneNumber"}
                    name="projectPhoneNumber"
                    onChange={handleChange}
                    style={{ marginTop: "4px" }}
                    required // Assign the input type as "number"
                    inputProps={{
                      inputMode: "numeric", // Restrict input to numeric characters
                      pattern: "[0-9]*", // Allow only numeric characters
                    }}
                  />
                  <div>
                    <Stack
                      alignItems="center"
                      justifyContent="flexStart"
                      spacing={2}
                      mb={1}
                    >
                      <Typography variant="h6" color="#000">
                        Address
                      </Typography>
                    </Stack>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextFieldComponent
                          name="address.country"
                          variant="outlined"
                          label={"Country"}
                          onChange={handleChange}
                          fullWidth
                          onBlur={handleBlur}
                          inputProps={{
                            "data-testId": "country",
                          }}
                          required
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextFieldComponent
                          select
                          name="address.state"
                          variant="outlined"
                          label={"State"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          required
                        >
                          {state.map((stateValue: string, key: number) => (
                            <MenuItem key={key} value={`${stateValue}`}>
                              {stateValue}
                            </MenuItem>
                          ))}
                        </TextFieldComponent>
                      </Grid>

                      <Grid item xs={3}>
                        <TextFieldComponent
                          fullWidth
                          name="address.city"
                          onChange={handleChange}
                          label={"City"}
                          required
                          inputProps={{
                            "data-testId": "city",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      columnGap: "2rem",
                    }}
                  >
                    <UpCommingMedia />
                    <ProjectIcons />
                  </div>

                  {/* <CurrentInfraStory />
                  <UpcomingInfraStory />
                  <TourismAround />
                  <KeyPillars /> */}
                </PromiseCreationContainer>
              </>
            </Container>
          </Form>
        )}
      </Formik>
      {message && openFlashMessage ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={message ? message : "Something Went Wrong!"}
          isSuccess={true}
          cancelHandler={() => handleCloseFlashMessageDialog()}
        />
      ) : null}

      {error && openFlashMessage ? (
        <FlashMessageDialog
          shouldOpen={true}
          content={error}
          isSuccess={false}
          cancelHandler={() => handleCloseFlashMessageDialog()}
        />
      ) : null}
    </>
  );
};

export default UpcommingProject;
