import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { FormFieldContainer } from "components/ui-elements";
import Grid from "@mui/material/Grid";
import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConstants } from "../../../routes/RouteConstants";
import styles from "../../promise-management/promise-management-dashboard/PromiseManagement.module.css";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { StatusTypeEnum } from "enumerations/StatusTypeEnum";
import NotificationActions, {
  setNotificationsState,
} from "../../../redux-container/notification-management/NotificationsRedux";
import { AppUpdateActions } from "redux-container/notification-management/AppUpdatesRedux";

var Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

const actionDispatch = (dispatch: any) => {
  return {
    getNotifications: (params: any) =>
      dispatch(NotificationActions.getNotificationsRequest(params)),
    getAppUpdatesRequest: (params: any) =>
      dispatch(AppUpdateActions.getAppUpdatesRequest(params)),
  };
};

const NotificationManagementDashboard = () => {
  const { getNotifications, getAppUpdatesRequest } = actionDispatch(
    useDispatch()
  );
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate();

  const isGraphDataFetched = useSelector(
    (state: any) => state?.notifications?.isGraphDataFetched
  );
  const totalCount = useSelector(
    (state: any) => state?.notifications.totalCount
  );
  const appUpdatesTotalCount = useSelector(
    (state: any) => state?.appUpdate.totalCount
  );

  const breadcrumbRoutes: any = [
    {
      name: "Notification Management",
      route: RouteConstants.notificationManagementDashboard,
    },
  ];

  useEffect(() => {
    const params = {
      size: rowsPerPage,
    };
    loadData(params);
  }, []);
  // Notification
  useEffect(() => {
    if (isGraphDataFetched) {
      setNotificationsState("isGraphDataFetched", false);
      configureNotificationChart();
      // configureAppUpdatesChart();
    }
  }, [isGraphDataFetched]);

  const loadData = (params: any) => {
    getNotifications(params);
    getAppUpdatesRequest(params);
  };

  const navigateToNotifications = () => {
    navigate(RouteConstants.notificationList, {
      state: { isNavigationFromSidemenu: true },
    });
  };
  const navigateToAppUpdates = () => {
    navigate(RouteConstants.appUpdates, {
      state: { isNavigationFromSidemenu: true },
    });
  };

  const configureNotificationChart = () => {
    Highcharts.chart("notification-container", {
      chart: {
        backgroundColor: "#f7f6ff",
        plotBackgroundColor: "#f7f6ff",
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          dataLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
              color: "black",
              fontSize: "13px",
              align: "left",
            },
            filter: {
              property: "name",
              operator: "===",
              value: "Notifications",
            },
          },
          borderWidth: 0,
          minSize: 80,
        },
        size: "89%",
        series: {
          animation: true,
        },
      },
      series: [
        {
          type: "pie",
          name: "Total",
          innerSize: "80%",
          data: [
            {
              name: "Active Notifications",
              y: totalCount,
              color: "#54b652",
              sliced: true,
            },
            {
              name: "InActive Notifications",
              y: 0,
              color: "#f37c7c",
              sliced: true,
            },
          ],
        },
      ],
    });
  };
  const configureAppUpdatesChart = () => {
    Highcharts.chart("appUpdates-container", {
      chart: {
        backgroundColor: "#f7f6ff",
        plotBackgroundColor: "#f7f6ff",
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          slicedOffset: 0,
          dataLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
              color: "black",
              fontSize: "13px",
              align: "left",
            },
            filter: {
              property: "name",
              operator: "===",
              value: "Notifications",
            },
          },
          borderWidth: 0,
          minSize: 80,
        },
        size: "89%",
        series: {
          animation: true,
        },
      },
      series: [
        {
          type: "pie",
          name: "Total",
          innerSize: "80%",
          data: [
            {
              name: "Active Notifications",
              y: appUpdatesTotalCount,
              color: "#54b652",
              sliced: true,
            },
            {
              name: "InActive Notifications",
              y: 0,
              color: "#f37c7c",
              sliced: true,
            },
          ],
        },
      ],
    });
  };

  return (
    <Fragment>
      <PageHeading title="Notification Management" />
      <BreadCrumbs routes={breadcrumbRoutes}></BreadCrumbs>

      <Container>
        <div className="heading-action-holder">
          <h4 className="heading">Dashboard</h4>

          <div>
            <Button
              variant="contained"
              onClick={navigateToNotifications}
              // autoFocus
              disableElevation
              endIcon={<ChevronRightRoundedIcon />}
              className="btn btn-dark margin-right-15px"
            >
              Go to Notifications
            </Button>
            {/* <Button
              variant="contained"
              onClick={navigateToAppUpdates}
              // autoFocus
              disableElevation
              endIcon={<ChevronRightRoundedIcon />}
              className="btn btn-dark margin-right-15px"
            >
              Go to App Updates
            </Button> */}
          </div>
        </div>

        <h5 className="graph-container-heading">Notifications</h5>

        <FormFieldContainer>
          <Grid className="graph-data-container">
            <Typography className="graph-heading">
              Notification Overview
            </Typography>
            <Grid container spacing={2}>
              {/* paddingY="70px" */}
              {/* style={{ minHeight: "4rem",alignItems:"center" }} */}
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Box id="notification-container" className="graph-holder" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                className={styles["total-stats-holder-notification"]}
                onClick={navigateToNotifications}
              >
                <span className="stat-card-total-value">{totalCount}</span>
                <span className="stat-card-total-heading">
                  Total Published Notifications
                </span>
                <span className={styles["stat-card-total-icon"]}>
                  <ChevronRightRoundedIcon />
                </span>
              </Grid>
            </Grid>
          </Grid>
        </FormFieldContainer>
        {/* <h5
          className="graph-container-heading"
          style={{
            marginTop: "15px",
          }}
        >
          App Updates
        </h5> */}

        {/* <FormFieldContainer>
          <Grid className="graph-data-container">
            <Typography className="graph-heading">
              App Update Overview
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Box id="appUpdates-container" className="graph-holder" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                className={styles["total-stats-holder-notification"]}
                onClick={navigateToAppUpdates}
              >
                <span className="stat-card-total-value">
                  {appUpdatesTotalCount}
                </span>
                <span className="stat-card-total-heading">
                  Total Published App Updates
                </span>
                <span className={styles["stat-card-total-icon"]}>
                  <ChevronRightRoundedIcon />
                </span>
              </Grid>
            </Grid>
          </Grid>
        </FormFieldContainer> */}
      </Container>
    </Fragment>
  );
};

export default NotificationManagementDashboard;
